export default function TrustElements ({
  elements,
  titleClassName = 'text-darkblue fw-bold fs-6',
  descriptionClassName = '',
  containerClassName = 'row mt-5 align-items-start',
  elementsContainerClassName = 'd-flex align-items-start px-lg-5 py-3',
  svgConfig = {
    width: 50,
    height: 50,
    color: '#1A7DFF'
  }
}) {
  return (
    <div className={containerClassName}>
      {
        elements.map((element, index) => (
          <div
            key={index}
            className="col-12 col-lg-6"
          >
            <div className={elementsContainerClassName}>
              <div>
                <svg
                  width={svgConfig.width}
                  height={svgConfig.height}
                  viewBox={`0 0 ${svgConfig.width} ${svgConfig.height}`}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.5 27.625L24.375 32.5L32.5 21.125M45.5 26C45.5 28.5608 44.9956 31.0965 44.0157 33.4623C43.0357 35.8282 41.5993 37.9778 39.7886 39.7886C37.9778 41.5993 35.8282 43.0357 33.4623 44.0157C31.0965 44.9956 28.5608 45.5 26 45.5C23.4392 45.5 20.9035 44.9956 18.5377 44.0157C16.1718 43.0357 14.0222 41.5993 12.2114 39.7886C10.4007 37.9778 8.96432 35.8282 7.98435 33.4623C7.00438 31.0965 6.5 28.5608 6.5 26C6.5 20.8283 8.55446 15.8684 12.2114 12.2114C15.8684 8.55446 20.8283 6.5 26 6.5C31.1717 6.5 36.1316 8.55446 39.7886 12.2114C43.4455 15.8684 45.5 20.8283 45.5 26Z"
                    stroke={svgConfig.color}
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="ms-3">
                <span className={titleClassName}>
                  {element.title}
                </span>
                <p className={descriptionClassName}>
                  {element.description}
                </p>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}
