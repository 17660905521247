import Image from 'next/future/image'
import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { useQuery } from '@apollo/client'
import { GET_LANGUAGES } from '../services/graphql/queries/getLanguages'
import { useTranslation } from 'next-i18next'
import Cookies from 'js-cookie'

export default function LanguageSwitch () {
  const router = useRouter()
  const { t } = useTranslation('languages')
  const { pathname, asPath, query } = router
  const { data } = useQuery(GET_LANGUAGES)
  const languages = useMemo(() => data?.languages.filter(language => ['DE', 'EN'].includes(language.name)) || [], [data])
  const switchLanguage = async locale => {
    // Set Next Locale, so Next.js can update the Accept-Language header
    // This is nice because so the language does not reset for the user
    await fetch('/api/language', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ locale })
    })

    // Set cookie with locale for SSR
    Cookies.set('NEXT_LOCALE', locale, { expires: 30 })

    router.push({ pathname, query }, asPath, { locale })
  }

  return (
    <div className="language-switch bg-midnight py-2">
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-auto">
            <label htmlFor="languageSwitch"
              className="me-2"
            >
              <span className="visually-hidden">
                {t('select', { ns: 'languages' })}
              </span>
              <Image alt=""
                src="/images/icons/language.svg"
                width={16}
                height={16}
              />
            </label>
            <select id="languageSwitch"
              className="select--no-bg"
              value={router.locale.toUpperCase()}
              onChange={(e) => switchLanguage(e.target.value.toLowerCase())}
            >
              {
                languages.map(locale => (
                  <option key={locale.id}
                    value={locale.name}
                  >{t(locale.name, { ns: 'languages' })}</option>
                ))
              }
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}
