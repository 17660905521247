import { gql } from '@apollo/client'

export const GET_APPLICATIONS = gql`
    query GetApplications($active_only: Boolean) {
        applications(active_only: $active_only) {
            id,
            name,
            versions {
                id,
                name
            }
        }
    }
`
