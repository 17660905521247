import { i18n } from 'next-i18next'

// This is currently only used for backend validation errors like extension.validations
export default function useTranslatedRoutes (language) {
  /**
   * We just check the first one because if this is undefined, the rest will be too
   * NextI18n only loads the current language and methods like getResourceBundle will return undefined if the language is not loaded
   */
  if (!i18n.hasResourceBundle(language.toLowerCase(), 'applications')) {
    i18n.addResourceBundle(language.toLowerCase(), 'applications', require('../public/locales/' + language.toLowerCase() + '/applications.json'))
    i18n.addResourceBundle(language.toLowerCase(), 'categories', require('../public/locales/' + language.toLowerCase() + '/categories.json'))
    i18n.addResourceBundle(language.toLowerCase(), 'services', require('../public/locales/' + language.toLowerCase() + '/services.json'))
    i18n.addResourceBundle(language.toLowerCase(), 'routes', require('../public/locales/' + language.toLowerCase() + '/routes.json'))
  }

  const i18nSoftwares = i18n.getResourceBundle(language.toLowerCase(), 'applications')
  const i18nCategories = i18n.getResourceBundle(language.toLowerCase(), 'categories')
  const i18nRoutes = i18n.getResourceBundle(language.toLowerCase(), 'routes')
  const i18nServices = i18n.getResourceBundle(language.toLowerCase(), 'services')

  const getCategoryUrl = (category) => {
    const categorySlug = i18nCategories[category?.name]?.toLowerCase()
      .replaceAll(' ', '_')
      .replaceAll('/', '_sl_')

    return `/plugins/${i18nRoutes.category}/${categorySlug}`
  }

  const getServiceUrl = (service) => {
    const serviceSlug = i18nServices.enum[service?.name]?.toLowerCase()
      .replaceAll(' ', '_')
      .replaceAll('/', '_sl_')

    return `/services/${i18nRoutes.service}/${serviceSlug}`
  }

  const getSoftwareUrl = (software) => {
    return `/plugins/${i18nRoutes.software}/${i18nSoftwares[software?.name]?.toLowerCase().replaceAll(' ', '_')}`
  }

  const getSoftwareName = (application) => {
    const applicationName = Object
      .keys(i18nSoftwares)
      .find(key => i18nSoftwares[key]?.toLowerCase() === application.replaceAll('_', ' '))

    if (applicationName) {
      return applicationName
    }

    return ''
  }

  const getServiceName = (service) => {
    const serviceName = Object
      .keys(i18nServices.enum)
      .find(key => i18nServices.enum[key]?.toLowerCase() === service.replaceAll('_', ' '))

    if (serviceName) {
      return serviceName
    }

    return ''
  }

  const getCategoryName = (category) => {
    const categoryName = Object
      .keys(i18nCategories)
      .find(key => i18nCategories[key]?.toLowerCase() === category
        .replaceAll('_sl_', '/')
        .replaceAll('_', ' ')
      )

    if (categoryName) {
      return categoryName
    }

    return ''
  }

  return {
    getCategoryUrl,
    getSoftwareUrl,
    getSoftwareName,
    getCategoryName,
    getServiceUrl,
    getServiceName
  }
}
